window.addEventListener('DOMContentLoaded', function () {
  const headerPopupVideo = document.querySelector('.header-popup-video');
  const pageHeaderBtn = document.querySelectorAll('.popup-btn');

  pageHeaderBtn.forEach((element) => {
    element.addEventListener('click', function () {
      headerPopupVideo.classList.toggle('opened');
      console.log('clicked');
    });
  });
});
