import * as Focus from '../common/focus';

window.addEventListener('load', function () {
  const toggleSearch = document.querySelectorAll('.toggleSearch');
  const searchModal = document.querySelector('#search-modal');
  const closeSearch = document.querySelector('#search-close');
  const dropdowns = document.querySelectorAll('.nav-primary .dropdown');

  function closeSearchModal() {
    searchModal.classList.remove('opened');
    closeSearch.setAttribute('aria-expanded', 'false');

    if (searchModal.classList.contains('search-modal--window')) {
      Focus.untrapFocusInside(jQuery('#search-modal'));
      document.documentElement.classList.remove('lockedPosition');
    }
  }

  function openSearchModal() {
    const openEvent = new Event('opensearchmodal');
    searchModal.classList.add('opened');
    closeSearch.setAttribute('aria-expanded', 'true');
    window.dispatchEvent(openEvent);

    if (searchModal.classList.contains('search-modal--window')) {
      Focus.trapFocusInside(jQuery('#search-modal'));
      document.documentElement.classList.add('lockedPosition');
    }
  }

  if (toggleSearch) {
    toggleSearch.forEach((toggleElement) => {
      if (toggleElement && closeSearch) {
        toggleElement.addEventListener('click', function () {
          if (searchModal.classList.contains('opened')) {
            closeSearchModal();
          } else {
            openSearchModal();
          }
        });

        closeSearch.addEventListener('click', function () {
          if (searchModal.classList.contains('opened')) {
            closeSearchModal();
          }
        });
      }
    });
  }

  // close search bar when dropdown opens from hover
  if (
    0 < dropdowns.length &&
    !searchModal.classList.contains('search-modal--window')
  ) {
    dropdowns.forEach(function (dropdown) {
      dropdown.addEventListener('mouseover', function () {
        if (searchModal.classList.contains('opened')) {
          closeSearchModal();
        }
      });
    });
  }
});
