/**
 * Reference accessible navigation: https://adrianroselli.com/2019/06/link-disclosure-widget-navigation.html
 *
 * @param {Object} $ - jQuery
 * @todo Rendre plus comme Vie des Arts
 */
import $ from 'jquery';
import domReady from '@wordpress/dom-ready';

// (!) These classes and selector also need to be changed in the _dropdown-menu.scss (!)

const $navWithDropdown = $('.nav--with-dropdown');

const dropdownContainerSelector = '.dropdown';

const dropdownToggleClass = 'dropdown__toggle';
const activeClass = 'opened';

const openedSubnav = {};

let uniqueNameCount = 0;

function createUniqueSubnavName() {
  uniqueNameCount++;

  return 'subnav_' + uniqueNameCount;
}

function tryClosingSubnavOnKeyUp(event) {
  if ('Escape' !== event.key) {
    // BAIL. Not a key worth closing for.
    return;
  }

  const openedSubnavKeys = Object.keys(openedSubnav);
  if (!openedSubnavKeys.length) {
    // BAIL. Need at least 1 subnav to close.
    return;
  }

  /* jshint ignore:start */
  closeSubnav(openedSubnavKeys[openedSubnavKeys.length - 1]);
  /* jshint ignore:end */
}

function tryClosingSubnav(event) {
  for (const subnavName in openedSubnav) {
    if ('undefined' === typeof subnavName) {
      continue;
    }

    const $target = openedSubnav[subnavName].find(event.target);
    if (!$target.length) {
      /* jshint ignore:start */
      closeSubnav(subnavName);
      /* jshint ignore:end */

      continue;
    }
  }
}

function getOpenedSubnavByName(subnavName) {
  if (!openedSubnav[subnavName]) {
    console.warn(
      subnavName + " n'existe pas encore. (getOpenedSubnavByName())"
    );
    return $();
  }

  return openedSubnav[subnavName];
}

function getSubnavName($subnav) {
  let subnavName = $subnav.data('subnav');

  if (!subnavName) {
    subnavName = createUniqueSubnavName();
    $subnav.data('subnav', subnavName);
  }

  return subnavName;
}

function setOpenedSubnav($subnav) {
  const subnavName = getSubnavName($subnav);

  openedSubnav[subnavName] = $subnav;
}

function openSubnav(subnavName) {
  const $subnav = getOpenedSubnavByName(subnavName);
  $subnav.addClass(activeClass);
  // Items are not visible for the moment and also not targetable by that fact.
  // This delay will permit a focus.
  setTimeout(
    function () {
      this.find('li a').first().focus();
      const $toggle = this.find('.' + dropdownToggleClass).first();
      $toggle
        .attr('aria-expanded', true)
        .attr('aria-label', $toggle.data('label-close'));

      if (1 === Object.keys(openedSubnav).length) {
        $(document)
          .on('focusin.closing_subnav', tryClosingSubnav)
          .on('click.closing_subnav', tryClosingSubnav)
          .on('keyup.closing_subnav', tryClosingSubnavOnKeyUp);
      }
    }.bind($subnav),
    10
  );
}

function closeSubnav(subnavName) {
  const $subnav = getOpenedSubnavByName(subnavName);
  $subnav.removeClass(activeClass);

  const $toggle = $subnav.find('.' + dropdownToggleClass).first();
  $toggle
    .attr('aria-expanded', false)
    .attr('aria-label', $toggle.data('label-open'));

  delete openedSubnav[subnavName];

  // Supprimer les événements si il n'y a plus de menu ouvert.
  if (!Object.keys(openedSubnav).length) {
    $(document)
      .off('click.closing_subnav')
      .off('focusin.closing_subnav')
      .off('keyup.closing_subnav');
  }
}

/////////////// ON READY /////////////////
domReady(() => {
  $navWithDropdown
    .find('.' + dropdownToggleClass)
    .on('click', function (event) {
      event.stopPropagation();

      const $dropdownContainer = $(this).closest(dropdownContainerSelector);

      if (!$dropdownContainer.hasClass(activeClass)) {
        // On pourrait le faire tout le temps, mais en théorie on a déjà set un sub menu au close.
        // C'est donc plus performant de le faire juste ici.
        setOpenedSubnav($dropdownContainer);

        openSubnav(getSubnavName($dropdownContainer));
      } else {
        closeSubnav(getSubnavName($dropdownContainer));
      }
    });
});
