import Accordion from 'accordion-js';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const careerListing = document.querySelector('.career-listing');
  if (careerListing) {
    const accordionsContainer = Array.from(
      document.querySelectorAll('.career-accordion-container')
    );
    new Accordion(accordionsContainer, {
      duration: 400,
      showMultiple: true,
    });
  }
});
